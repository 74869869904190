import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, graphql, navigate } from "gatsby";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TeIka, { TileZoom } from "../components/te-ika";
import teIkaTiles from "../../static/files/mural/tiles.json";
import "./page.css";
import "./gallery.css";
import Poster from "../components/poster";
import ArrowDown from "../components/arrow";
import jump from "../jump";
import Image from "gatsby-image";
import hashState from "../hashstate";

type Props = {
  slug: string;
  previous: string;
  next: string;
  data: any;
  path: string;
  pageContext: any;
  location: string;
  plainPage: boolean;
};

function PageTemplate(props: Props) {
  const { location, data, pageContext, path } = props;
  const isTeIka = path === "/te-ika-a-akoranga/";
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next, plainPage } = pageContext;
  const [isSlideIn, setIsSlideIn] = useState(false);
  const { gallery, galleryCaption } = post.frontmatter;
  const [fromId] = useState<string>(hashState());

  const [isFaded, setIsFaded] = useState(true);

  const goHome = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSlideIn(false);
    setIsFaded(null);
    jump(0, window.scrollY, {
      callback: () => {
        let timer = setTimeout(() => {
          navigate(`/#${fromId}`);
        }, 1000);
      },
    });
  };

  const [tileActive, setTileActive] = useState();

  useEffect(() => {
    function onEsc(e) {
      if (tileActive) return;
      if (e.keyCode === 27) {
        goHome(e);
      }
    }
    window.addEventListener("keydown", onEsc);
    return () => {
      window.removeEventListener("keydown", onEsc);
    };
  }, [fromId, tileActive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSlideIn(true);
    }, 25);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const tilesKeys = Object.keys(teIkaTiles).sort();

  const numberOfGalleryImages =
    (gallery ? gallery.length : 0) + (isTeIka ? 1 : 0);

  const carouselRef = useRef<HTMLDivElement>();

  const toggleFullscreen = () => {
    const carouselDiv = carouselRef.current;
    if (!carouselDiv) return;
    const currentIndex = Math.round(
      carouselDiv.scrollLeft / carouselDiv.offsetWidth
    );
    if (!carouselDiv.requestFullscreen || !document.exitFullscreen) {
      console.log("No fullscreen support on your device");
      return;
    }
    const restoreScroll = () => {
      const move = () => {
        if (!carouselDiv || !carouselDiv.parentElement) return;
        carouselDiv.scrollLeft = carouselDiv.offsetWidth * currentIndex;
      };
      move();
      setTimeout(move, 50);
      setTimeout(move, 100);
    };

    if (!document.fullscreenElement) {
      carouselDiv
        .requestFullscreen()
        .then((...args) => {
          console.log("fullscreen success", ...args);
          restoreScroll();
        })
        .catch((e) => {
          console.error("Error fullscreen", e);
        });
    } else {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then((...args) => {
            console.log("exit fullscreen success", ...args);
            restoreScroll();
          })
          .catch((e) => {
            console.error("Error exiting fullscreen", e);
          });
      }
    }
  };

  const thumbnail =
    post &&
    post.frontmatter &&
    post.frontmatter.thumbnail &&
    post.frontmatter.thumbnail.childImageSharp &&
    post.frontmatter.thumbnail.childImageSharp.fixed &&
    post.frontmatter.thumbnail.childImageSharp.fixed.src;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        thumbnail={thumbnail}
      />
      {tileActive && (
        <TileZoom
          setActive={setTileActive}
          active={tileActive}
          tilesKeys={tilesKeys}
          tiles={teIkaTiles}
        />
      )}
      <ArrowDown direction="down" />
      <div
        className={`page-content ${isSlideIn ? "page-content--visible" : ""}`}
        style={
          tileActive
            ? {
                zIndex: -1,
              }
            : {}
        }
      >
        <div className="page-content__inner">
          <h2 className="section-heading">{post.frontmatter.title}</h2>
          {numberOfGalleryImages ? (
            <div>
              <div className="gallery">
                <h3 className="section-heading">
                  Gallery ({numberOfGalleryImages} image
                  {numberOfGalleryImages > 1 ? "s" : ""})
                </h3>
                {numberOfGalleryImages > 1 && (
                  <Fragment>
                    <button
                      className="carousel__previous"
                      onClick={() => {
                        const el = carouselRef.current;
                        const scrollLeft = el.scrollLeft - el.offsetWidth;
                        el.scrollTo({
                          top: 0,
                          left: scrollLeft,
                          behavior: "smooth",
                        });
                      }}
                      aria-label="Previous gallery image"
                    >
                      〈
                    </button>
                    <button
                      className="carousel__next"
                      onClick={() => {
                        const el = carouselRef.current;
                        const scrollLeft = el.scrollLeft + el.offsetWidth;
                        el.scrollTo({
                          top: 0,
                          left: scrollLeft,
                          behavior: "smooth",
                        });
                      }}
                      aria-label="Next gallery image"
                    >
                      〉
                    </button>
                  </Fragment>
                )}
                <div
                  className="carousel"
                  ref={carouselRef}
                  tabIndex={0}
                  onClick={toggleFullscreen}
                >
                  {isTeIka && teIkaTiles && (
                    <div className="carousel__item">
                      <TeIka
                        tiles={teIkaTiles}
                        tilesKeys={tilesKeys}
                        setTileActive={setTileActive}
                      />
                    </div>
                  )}
                  {gallery &&
                    gallery.map((galleryImage, index) => {
                      if (!galleryImage) {
                        throw Error(`Gallery image #${index} can't be found.`);
                      }
                      return (
                        <div className="carousel__item">
                          <Image fluid={galleryImage.childImageSharp.fluid} />
                          {galleryCaption && (
                            <div className="carousel__caption">
                              {galleryCaption && galleryCaption[index]}
                              <span>&nbsp;</span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <footer className="footer">
            <ul className="prevnext">
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
            <Bio />
          </footer>
        </div>
      </div>
      <Poster
        isFaded={isFaded}
        setIsFaded={setIsFaded}
        index={-1}
        node={post}
        isFixed
        gotoHome
        gotoHomeCallback={goHome}
        hideDescription={plainPage}
      />
    </Layout>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html

      fields {
        slug
      }

      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        poster {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
        thumbnail: poster {
          childImageSharp {
            fixed(width: 1024) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        galleryCaption
        gallery {
          childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`;
