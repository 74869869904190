import React, { useState, Fragment, useEffect, useCallback } from "react";
import CSS from "csstype";
import "./teika.css";

const DEFAULT_OFFSET_WIDTH = 320;
const KEY_ESC = 27;

function TeIka({ tiles, tilesKeys, setTileActive }) {
  const [teikaWidth, setTeikaWidth] = useState(null);

  const ref = useCallback(node => {
    if (node !== null) {
      setTeikaWidth(node.offsetWidth);
    }
  }, []);

  const sideLength =
    (teikaWidth || DEFAULT_OFFSET_WIDTH) / tiles[tilesKeys[0]].length;
  const sideLengthPx = `${sideLength}px`; // width or height of a tile

  return (
    <div className="teika" ref={ref}>
      {tilesKeys.map((tileRowKey, rowIndex) => {
        return (
          <div
            key={rowIndex}
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {tiles[tileRowKey].map((tileCell, tileCellIndex) => {
              const columnIndex = tileCellIndex + 1;
              return (
                <button
                  style={{
                    backgroundImage: `url("/files/mural/${tileLetters[rowIndex]}${columnIndex}-44.jpg")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: sideLengthPx,
                    height: sideLengthPx
                  }}
                  onClick={e => {
                    setTileActive([columnIndex, rowIndex, Date.now()]);
                    eventuallyFocus(columnIndex, rowIndex);
                  }}
                  className="teika__zoom-in-button"
                  key={`${rowIndex}${columnIndex}`}
                >
                  View tile {tileRowKey} {columnIndex}
                </button>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

const positions = Array.from(new Array(9)).map((val, index) => index);

const TILE_SIZE = 0.333;
const positionToCursor = [
  "nw-resize",
  "n-resize",
  "ne-resize",
  "w-resize",
  "zoom-out",
  "e-resize",
  "sw-resize",
  "s-resize",
  "se-resize"
];

const gutters = 50; // px guttering

const SLIDING_CONTAINER = "slidingContainer";

export function TileZoom({ active, setActive, tiles, tilesKeys }) {
  const [transform, _setTransform] = useState(null);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const squareLength = Math.min(windowWidth, windowHeight) - gutters;
  const centerLeft =
    squareLength === windowWidth ? 0 : (windowWidth - squareLength) / 2;
  const centerTop =
    squareLength === windowHeight ? 0 : (windowHeight - squareLength) / 2;
  const width = TILE_SIZE * squareLength;
  const height = TILE_SIZE * squareLength;

  const setTransform = newTransform => {
    if (newTransform !== null && transform) {
      // if there's an existing transform then wait until the animation is finished
      // but only
      return;
    }

    if (newTransform) {
      const backgroundImage = zoomedImageUrl(
        active[0] - newTransform[0] * 2,
        active[1] - newTransform[1] * 2
      );
      const img = new Image(1, 1); // preload
      img.src = backgroundImage;
    }
    _setTransform(newTransform);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    const keydown = event => {
      if (event.which === KEY_ESC) {
        setActive(null);
      }

      if (!active) return;

      switch (event.which) {
        case 37:
          // Left
          event.preventDefault();
          setTransform([+1, 0]);
          break;
        case 38:
          // Up
          event.preventDefault();
          setTransform([0, +1]);
          break;
        case 39:
          // Right
          event.preventDefault();
          setTransform([-1, 0]);
          break;
        case 40:
          // Down
          event.preventDefault();
          setTransform([0, -1]);
          break;
        default:
        // pass
      }
    };

    document.addEventListener("keydown", keydown);

    return () => {
      document.removeEventListener("keydown", keydown);
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [active, setActive, transform, setTransform]);

  const onTransitionEnd = useCallback(
    e => {
      if (e.target.id !== SLIDING_CONTAINER) {
        // Ignore. Some other bubbling transition event that we don't care about
        return;
      }

      const newActive = [active[0] - transform[0], active[1] - transform[1]];

      setActive(newActive);
      setTransform(null);

      eventuallyFocus(newActive[0], newActive[1]);
    },
    [active, setActive, transform, setTransform]
  );

  return (
    <Fragment>
      <div
        className="tilezoom-underlay"
        onClick={() => {
          if (transform) return; // ignore clicking during animation
          setActive(null);
        }}
      />

      <div
        id={SLIDING_CONTAINER}
        style={{
          position: "absolute",
          left: window.scrollX,
          top: window.scrollY,
          transition: transform ? "transform 0.5s" : undefined,
          transform: transform
            ? `translate(${transform[0] * width}px, ${transform[1] * width}px)`
            : undefined
        }}
        onTransitionEnd={onTransitionEnd}
      >
        {positions.map(position => {
          const thisColumn = position % 3;
          const thisRow = Math.floor(position / 3);
          const thisTilePosition = relativePosition(
            thisColumn,
            thisRow,
            active[0],
            active[1]
          );

          return (
            <ZoomedTile
              key={`${thisTilePosition[0]}-${thisTilePosition[1]}`}
              position={position}
              active={active}
              setActive={setActive}
              transform={transform}
              setTransform={setTransform}
              thisTilePosition={thisTilePosition}
              centerLeft={centerLeft}
              centerTop={centerTop}
              width={width}
              height={height}
              squareLength={squareLength}
              tiles={tiles}
              tilesKeys={tilesKeys}
              thisColumn={thisColumn}
              thisRow={thisRow}
            />
          );
        })}
      </div>

      <div className="tilezoom-overlay" />
    </Fragment>
  );
}

const _ZoomedTile = ({
  position,
  active,
  setActive,
  transform,
  setTransform,
  thisTilePosition,
  centerLeft,
  centerTop,
  width,
  height,
  squareLength,
  tiles,
  tilesKeys,
  thisColumn,
  thisRow
}) => {
  const isCenter = position === 4;

  const [opacity, setOpacity] = useState(0);

  let backgroundImage = zoomedImageUrl(
    thisTilePosition[0],
    thisTilePosition[1]
  );

  if (thisTilePosition[0] < 0) {
    backgroundImage = null;
  } else if (thisTilePosition[1] < 0) {
    backgroundImage = null;
  } else if (thisTilePosition[0] > tiles[tilesKeys[0]].length) {
    backgroundImage = null;
  } else if (thisTilePosition[1] > tilesKeys.length) {
    backgroundImage = null;
  }

  const left = (thisColumn / 3) * squareLength + width / 2;
  const top = (thisRow / 3) * squareLength + height / 2;

  const isMovingTo =
    transform &&
    thisTilePosition[0] === active[0] - transform[0] &&
    thisTilePosition[1] === active[1] - transform[1];

  const style: CSS.Properties = {
    position: "absolute",
    left: `${centerLeft + left}px`,
    top: `${centerTop + top}px`,
    width: `${width}px`,
    height: `${height}px`,
    marginLeft: `-${width / 2}px`,
    marginTop: `-${width / 2}px`,
    boxShadow: isCenter || isMovingTo ? "0px 0px 3px" : "none",
    opacity,
    transition: "opacity 1.5s",
    filter: `blur(${isCenter || isMovingTo ? "0" : "4px"})`,
    zIndex: isCenter || isMovingTo ? 500 : "auto",
    cursor: positionToCursor[position],
    maxWidth: "none",
    maxHeight: "none"
  };

  return (
    <img
      id={makeZoomId(thisTilePosition[0], thisTilePosition[1])}
      style={style}
      className="zoomed-tile"
      onClick={e => {
        e.stopPropagation(); // ensure event doesn't bubble up to the overlay's click
        e.preventDefault();
        if (isCenter) {
          setActive(null);
        } else {
          setTransform([
            active[0] - thisTilePosition[0],
            active[1] - thisTilePosition[1]
          ]);
        }
      }}
      onError={e => {
        setOpacity(0); // any 404s / 500s shouldn't corrupt image so hit those "[X]" images
      }}
      tabIndex={0}
      src={backgroundImage}
      onLoad={() => {
        setOpacity(1);
      }}
      alt={`${tileLetters[thisTilePosition[1]]} ${thisTilePosition[0]}`}
    />
  );
};

const ZoomedTile = React.memo(_ZoomedTile);

const eventuallyFocus = (left, top) => {
  setTimeout(() => {
    const target = document.getElementById(makeZoomId(left, top));
    if (target) target.focus();
  }, 60);
};

const zoomedImageUrl = (x, y) => `/files/mural/${tileLetters[y]}${x}-550.jpg`;

const makeZoomId = (left, top) => `zoomed-tile-${left}-${top}`;

const tileLetters = "tsrqpnmlkjhgfedcba".split(""); // the tile layout starts bottom-left from 'a' and ascends to 't'.

function relativePosition(column, row, centerTileColumn, centerTileRow) {
  const thisRow = centerTileRow + row - 1;
  const thisColumn = column + centerTileColumn - 1;
  return [thisColumn, thisRow];
}

export default TeIka;
